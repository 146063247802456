import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import GESLogo from '../img/ges-logo-text-black.jpg'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404" />
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-8">
            <div className="section content">
              <h1 className="title is-size-1">Oops!</h1>
              <h3 className="subtitle is-size-3">
                We can't seem to find the page you're looking for
              </h3>
              <p>
                <strong>Error code: 404</strong>
              </p>
              <p>Here are some helpful links instead:</p>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="column is-4">
            <img src={GESLogo} />
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
